import { useSelector, useDispatch } from "react-redux";
import { setUser, userLoggedOut } from "store/auth/userSlice";
import { clearSettingData } from "store/settings/settingsSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import axiosInstance from "apiServices/axiosInstance";

import { setThemeColor, setThemeColorLevel } from "store/theme/themeSlice";
import { themeConfig } from "configs/theme.config";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, expired } = useSelector((state) => state.auth.session);

  const onThemeColorChange = (value) => {
    dispatch(setThemeColor(value));
  };

  const onThemeColorLevelChange = (value) => {
    dispatch(setThemeColorLevel(value));
  };

  const user = useSelector((state) => state.auth.user);

  const signIn = async ({ email, password }) => {
    try {
      const formData = {
        email: email,
        password: password,
      };
      const response = await axiosInstance.post(
        "doctor/doctor-sign-in",
        formData
      );
      if (response.status) {
        const { token, data } = response;
        dispatch(onSignInSuccess(token));
        const userData = data;
        userData &&
          dispatch(
            setUser({
              profile_photo: userData.profile_photo
                ? userData.profile_photo
                : "https://ecommerce-sapient.s3.ap-south-1.amazonaws.com/content/images/wellness-help/profile/admin/06426723156353848346.webp",
              name: userData.name ? userData.name : "",
              email: userData.email ? userData.email : "",
              authority: userData.role
                ? [userData.role.toLowerCase()]
                : ["doctor"],
              doctor_id: userData.doctor_id ? userData.doctor_id : 0,
              password: userData.password ? userData.password : "",
            })
          );
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return response;
      } else {
        return response;
      }
    } catch (errors) {
      // console.log("signIn error:", errors);
      return {
        status: false,
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(userLoggedOut());
    dispatch(clearSettingData());
    onThemeColorChange(themeConfig.themeColor);
    onThemeColorLevelChange(themeConfig.primaryColorLevel);
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  const checkAuthenticated = () => {
    if (expired > new Date().getTime()) {
      return true;
    } else {
      handleSignOut();
      return false;
    }
  };
  
  return {
    authenticated: token && signIn && checkAuthenticated(),
    signIn,
    signOut,
  };
}

export default useAuth;
